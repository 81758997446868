<template>
  <div>
    <portal to="page-top-title">Edit Blacklist #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'blacklists-single', params: { id: id } }" icon="fa-eye" text>Back to Blacklist</btn>
    </portal>

    <box-form action="edit" data-prop="formData" redirect="blacklists-list" :url="`/blacklists/${id}`" :loaded="loaded" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import Btn from '@/views/components/simple/Btn'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import Form from '@/modules/utils/Form'

export default {
  name: 'BlacklistsEdit',
  metaInfo () {
    return { title: `Blacklist #${this.id} | Edit` }
  },
  components: {
    BoxForm,
    Btn,
    FormGroupInput
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      loaded: false,
      formData: {
        name: Form.types.input()
      }
    }
  },
  created () {
    this.loadEntity()
  },
  methods: {
    loadEntity () {
      this.$http
        .get(`/blacklists/${this.id}`)
        .then((res) => {
          const { data: { data } } = res

          this.formData.name.value = data.name

          this.loaded = true
        })
    }
  }
}
</script>
